import { Params } from './path'

export enum RouteBookmarkTabs {
  Advisors = 'advisors',
  Analysis = 'analysis',
  Assessment = 'assessment',
  Benchmarking = 'benchmarking',
  Companies = 'companies',
  Deals = 'deals',
  Investors = 'investors',
  Similar = 'similar',
  Summary = 'summary',
  Updates = 'updates',
  Valuation = 'valuation',
  Conferences = 'conferences',
}

export interface BookmarksPathParams extends Params {
  listId: string
}

export const BOOKMARKS_PATH = '/bookmarks'

export const BOOKMARKS_CREATE_PATH = '/bookmarks/create'

export const BOOKMARKS_LIST_PATH = '/bookmarks/list/:listId'
export const BOOKMARKS_LIST_SUMMARY_PATH = `${BOOKMARKS_LIST_PATH}/${RouteBookmarkTabs.Summary}`
export const BOOKMARKS_LIST_COMPANIES_PATH = `${BOOKMARKS_LIST_PATH}/${RouteBookmarkTabs.Companies}`

export const BOOKMARKS_FILTERED_PATH = '/bookmarks/filtered/:listId'
export const BOOKMARKS_FILTERED_SUMMARY_PATH = `${BOOKMARKS_FILTERED_PATH}/${RouteBookmarkTabs.Summary}`
export const BOOKMARKS_FILTERED_COMPANIES_PATH = `${BOOKMARKS_FILTERED_PATH}/${RouteBookmarkTabs.Companies}`

/*
 * old app paths - used for redirect
 */
export const OLD_MY_LISTS_PATH = '/my-lists'
export const OLD_CUSTOM_ASSET_QUERY_PATH = '/my-lists/saved-filter/:listId'
export const OLD_CUSTOM_ASSET_LIST_PATH = '/my-lists/list/:listId'
