import { styled } from '@mui/material/styles'

import { virtualTableClasses } from './virtual-table'
import VirtualTableCell from './virtual-table-cell'

export interface VirtualTableHeadCellProps {
  width: number
  left: number
  position: 'absolute' | 'sticky'
  shadow: boolean
}

export const VirtualTableHeadCell = styled(VirtualTableCell)<VirtualTableHeadCellProps>(
  ({ shadow, position, theme, size }) => ({
    display: 'flex',

    ...(position === 'sticky' && {
      zIndex: 3,
    }),

    ...(size === 'small' && {
      marginBottom: 0,
      ...theme.typography.body2,
    }),

    [`.${virtualTableClasses.variantInline} &`]: {
      ...(shadow && {
        borderRight: `1px solid ${theme.palette.common.white}`,
        boxShadow: '4px 0px 5px -2px rgba(255, 255, 255, 1)',
      }),
    },
  })
)
