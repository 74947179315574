import { styled } from '@mui/material/styles'
import TableCell, { TableCellProps } from '@mui/material/TableCell'
import clsx from 'clsx'

import { virtualTableClasses } from './virtual-table'

export interface VirtualTableCellProps extends Omit<TableCellProps, 'width'> {
  width: number
  left: number
  position: 'absolute' | 'sticky'
  shadow: boolean
}

const VirtualTableCell = styled(
  ({ shadow, className, ...props }: VirtualTableCellProps) => {
    return (
      <TableCell
        className={clsx(className, shadow && virtualTableClasses.shadowCell)}
        component={'div'}
        {...props}
      />
    )
  },
  {
    shouldForwardProp: (propName) => {
      return !['width', 'left', 'position'].includes(propName as string)
    },
  }
)<VirtualTableCellProps>(({ theme, width, left, position, size, shadow }) => ({
  alignItems: 'center',
  width: width,
  minWidth: width,
  maxWidth: width,
  overflow: 'hidden',
  left,
  position,

  ...(shadow && {
    borderRight: `1px solid ${theme.palette.grey['300']}`,
    boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, .1)',
    clipPath: 'inset(0 -15px 0 0)',
  }),

  // Add additional padding on the right for small cells with a shadow
  ...(size === 'small' && {
    ...(shadow && {
      paddingRight: theme.spacing(1),
    }),
  }),

  // Increase padding on the first column after a column with shadow
  [`.${virtualTableClasses.variantInline} .${virtualTableClasses.shadowCell} + &`]: {
    paddingLeft: theme.spacing(1),
  },
}))

export default VirtualTableCell
