import { ChevronRightIcon } from '@gain/components/icons'
import Typography, { TypographyProps } from '@gain/components/typography'
import Badge, { badgeClasses } from '@mui/material/Badge'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { forwardRef, ReactNode } from 'react'

import { AvatarProps } from '../avatar'
import Logo, { LogoProps } from '../logo'

const StyledChevronRightIcon = styled(ChevronRightIcon)(({ theme }) => ({
  color: theme.palette.grey['600'],
  fontSize: 16,
}))

const StyledBadge = styled(Badge)({
  [`& .${badgeClasses.anchorOriginTopRightRectangular}`]: {
    top: 2,
    right: 2,
  },
})
export const contentLinkClasses = generateUtilityClasses('ContentLink', ['root', 'title'])

const StyledLink = styled(Link)({
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: 0,
  '&:hover[href]': {
    [`& .${contentLinkClasses.title}`]: {
      textDecoration: 'underline',
    },
  },
})

export type ContentLinkVariant = 'default' | 'logo'

export interface ContentLinkProps {
  href?: string | null
  endIcon?: ReactNode
  className?: string
  avatarProps?: AvatarProps
  region?: LogoProps['region']
  label?: string | null
  showBadge?: boolean
  variant?: ContentLinkVariant
  labelTypographyProps?: TypographyProps
  description?: string | null
  descriptionTypographyProps?: TypographyProps
}

const ContentLink = forwardRef<HTMLAnchorElement, ContentLinkProps>(function ContentLink(
  {
    href,
    className,
    avatarProps,
    region,
    label,
    labelTypographyProps,
    description,
    descriptionTypographyProps,
    variant = 'default',
    showBadge = false,
    endIcon,
    ...props
  },
  ref
) {
  if (!label) {
    return null
  }

  return (
    <StyledLink
      ref={ref}
      className={className}
      href={href || undefined}
      underline={'none'}
      {...props}>
      <Stack
        alignItems={'center'}
        direction={'row'}
        minWidth={0}
        spacing={1}>
        {avatarProps && (
          <StyledBadge
            color={'error'}
            invisible={!showBadge}
            variant={'dot'}>
            <Logo
              avatarProps={{
                imagePadding: 0.5,
                ...avatarProps,
              }}
              defaultIcon={avatarProps.children}
              region={region}
              size={24}
              variant={'rounded'}
            />
          </StyledBadge>
        )}

        {variant === 'default' && (
          <Stack
            direction={'column'}
            justifyContent={'center'}
            minWidth={0}>
            <Stack
              alignItems={'center'}
              direction={'row'}
              minWidth={0}
              spacing={0}>
              <Typography
                color={'text.primary'}
                variant={'body2'}
                disableTooltip
                {...labelTypographyProps}
                className={clsx(contentLinkClasses.title, labelTypographyProps?.className)}
                noWrap>
                {label}
              </Typography>
              {href && <StyledChevronRightIcon />}
              {endIcon}
            </Stack>
            {description && (
              <Typography
                color={'text.secondary'}
                variant={'body2'}
                {...descriptionTypographyProps}>
                {description}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </StyledLink>
  )
})

export default ContentLink
